import request from '@/utils/request'


// 查询新-用户品牌列表
export function listConfig(query) {
  return request({
    url: '/cigarette/public-config/list',
    method: 'get',
    params: query
  })
}

// 查询新-用户品牌分页
export function pageConfig(query) {
  return request({
    url: '/cigarette/public-config/page',
    method: 'get',
    params: query
  })
}

// 查询新-用户品牌详细
export function getConfig(data) {
  return request({
    url: '/cigarette/public-config/detail',
    method: 'get',
    params: data
  })
}

// 新增新-用户品牌
export function addConfig(data) {
  return request({
    url: '/cigarette/public-config/add',
    method: 'post',
    data: data
  })
}

// 修改新-用户品牌
export function updateConfig(data) {
  return request({
    url: '/cigarette/public-config/edit',
    method: 'post',
    data: data
  })
}

// 删除新-用户品牌
export function delConfig(data) {
  return request({
    url: '/cigarette/public-config/delete',
    method: 'post',
    data: data
  })
}
